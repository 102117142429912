<template>
  <v-container fluid class="mt-16 pt-md-8">
    <v-row justify="center">
      <v-col class="pa-md-0 px-5" cols="12" md="4" align="center">
        <p class="text-h1 mb-5">&#127881;</p>
        <div>
          <p>Thank you for ordering with us!</p>
          <p>Hold tight, one of our reps<br>will be in touch with you shortly!</p>
        </div>
        <div class="my-5 mb-10">
          <p>Your order number is</p>
          <p class="my-5 text-h4 font-weight-bold">{{ data.order_number }}</p>
          <p>Please screenshot this as a proof of order</p>
        </div>
        <p class="text-left text-caption grey--text mb-2">Order summary</p>
        <div 
          v-for="item in data.order_items" 
          :key="item.menu.name"
          class="mb-2"
        >
          <div class="d-flex mb-1">
            {{ item.menu.name }}
            <v-spacer></v-spacer>
            <span class="grey--text">x {{ item.quantity }}</span>
          </div>
          <v-divider></v-divider>
        </div>
        <v-col class="pr-0 pt-1" cols="12" align="right">
          <span class="primary--text">Total: <span class="pl-2">${{ parseFloat(this.data.total).toFixed(2) }}</span></span>
        </v-col>
        <v-col class="grey--text text-caption pa-0 mb-4" cols="12" align="left">
          <p class="text-body-1">Order details</p>
          <p>The Pancit helpline: 896 9546</p>
          <p v-if="data.booking">Slot: <span>{{ new Date(data.booking.slot.slot_date).toLocaleDateString() }}</span> 
            <span>
              ({{ weekday[new Date(data.booking.slot.slot_date).getDay()] }}, {{ data.booking.slot.slot_time }})
            </span>
          </p>
          <p v-if="data.delivery_method == 'Delivery'">Address: {{ data.delivery_address }}</p>
          <v-card v-if="data.delivery_method == 'Self pickup'" class="my-8">
            <v-row align="center">
              <v-col cols="12" sm="6" class="py-0" :class="$vuetify.breakpoint.smAndUp ? 'pr-0': ''">
                <v-img height="100%" src="https://pancit-production.sgp1.cdn.digitaloceanspaces.com/pancithouse.png"></v-img>
              </v-col>
              <v-col class="py-sm-0 px-6 grey--text text-caption">
                <p>Pickup address: No 26 Grd Flr Spg 779-5 Kg Jangsak Jalan Gadong<br><a href="https://maps.app.goo.gl/h3JgwGPWYiaTJM1t9" target="_blank">Open in Google Maps <v-icon small>mdi-open-in-new</v-icon></a></p>
              </v-col>
            </v-row>
          </v-card>
          <p v-if="data.notes != ''">Notes: {{ data.notes }}</p>
        </v-col>
        <v-col class="pa-0" cols="12">
          <v-btn 
            depressed
            color="primary"
            class="white--text"
            block
            :to="{name: 'home'}"
          >
            Back to Home
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      data: '',
      weekday: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
    }
  },
  mounted () {
    this.data = JSON.parse(this.$route.params.data);
  }
}
</script>